import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { getReduxStore } from 'common/store';
import { ThemeProvider } from '@mui/material';
import { theme } from 'common/constants/theme';
import { Provider } from 'react-redux';
import { App } from './root';
import './config/i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={getReduxStore()}>
    <Router>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Router>
  </Provider>
);
