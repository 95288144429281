import { nanoid } from 'nanoid';
import { SliderContent } from './types';

export const wrapTargetSlide = (
  contents: Record<number, SliderContent>,
  targetContentIndex: number,
  contentAmountBehind: number,
  contentAmountFront: number
) => {
  const output: Array<Partial<SliderContent>> = [];

  const pushFakeContents = (amount: number) => {
    for (let i = 0; i < amount; i += 1) {
      output.push({ id: nanoid(), fake: true });
    }
  };

  pushFakeContents(contentAmountBehind >= 100 ? 100 : contentAmountBehind);
  output.push(contents[targetContentIndex]);

  const targetSlideIndex = output.length - 1;

  pushFakeContents(contentAmountFront >= 100 ? 100 : contentAmountFront);

  if (contents[targetContentIndex - 1]) output[targetSlideIndex - 1] = contents[targetContentIndex - 1];
  if (contents[targetContentIndex + 1]) output[targetSlideIndex + 1] = contents[targetContentIndex + 1];

  return output;
};

export const changeTargetSlideWrap = (
  slides: Array<Partial<SliderContent>>,
  contents: Record<number, SliderContent>,
  currentSlideIndex: number
) => {
  const currentSlideElement = slides.find((_, index) => index === currentSlideIndex);
  const currentContentElementIndex = Number(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(contents).find(([_, value]) => value.id === currentSlideElement.id)[0]
  );

  const output = slides.map(slide => (slide.fake ? slide : { id: nanoid(), fake: true }));

  output[currentSlideIndex] = currentSlideElement;

  if (output[currentSlideIndex - 1] && contents[currentContentElementIndex - 1])
    output[currentSlideIndex - 1] = contents[currentContentElementIndex - 1];

  if (output[currentSlideIndex + 1] && contents[currentContentElementIndex + 1])
    output[currentSlideIndex + 1] = contents[currentContentElementIndex + 1];

  return output;
};
