import { Box } from '@mui/material';
import { Styles } from 'common/types';
import { ContentsSlider } from 'common/ui/videos-slider';
import { ReactElement, useCallback, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { mock } from './mock';

const styles: Styles = {
  container: { height: '100%', width: '100%' },
};

export function UserCarousel(): ReactElement {
  const { content_id } = useParams<{ content_id: string }>();
  const location = useLocation();

  const getBackPath = () => {
    let splitedPath = location.pathname.split('/');
    if (splitedPath.at(-1) === '/') splitedPath.pop();
    splitedPath = splitedPath.slice(0, splitedPath.length - 2);
    return splitedPath.join('/');
  };

  const getLazyLoadAmounts = useCallback(() => {
    let flag: 'behind' | 'front' = 'behind';
    let behind = 0;
    let front = 0;

    // eslint-disable-next-line consistent-return
    Object.values(mock).forEach(content => {
      // eslint-disable-next-line no-return-assign
      if (content.id === content_id) return (flag = 'front');

      if (flag === 'behind') behind += 1;
      if (flag === 'front') front += 1;
    });

    return { behind, front };
  }, [content_id]);

  // temporary without BE
  const { behind, front } = useMemo(() => {
    return getLazyLoadAmounts();
  }, [getLazyLoadAmounts]);

  return (
    <Box sx={styles.container}>
      <ContentsSlider
        contents={mock}
        initialContentId={content_id}
        contentAmountBehind={behind}
        contentAmountFront={front}
        backPath={getBackPath()}
      />
    </Box>
  );
}
