import { Box, Button, IconButton, Typography } from '@mui/material';
import { ReactElement } from 'react';
import UserHeaderImage from 'common/mock/images/header_mock.png';
import UserProfileImage from 'common/mock/images/avatar_mock.png';
import { Styles } from 'common/types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { theme } from 'common/constants/theme';
import { breakpointsNumber } from 'common/constants/breakpoints';

const styles: Styles = {
  headerImageWrapper: { position: 'relative', width: '100%', height: 'fit-content' },
  headerImageContainer: {
    width: '100%',
    height: 220,
    overflow: 'hidden',
    position: 'relative',

    [theme.breakpoints.down(breakpointsNumber.tablet)]: {
      height: 150,
    },
  },
  headerImage: { width: '100%', position: 'absolute', bottom: 0 },
  profileAvatar: { position: 'absolute', bottom: -30, left: 20 },
  contentWrapper: { padding: 2.5 },
  nameWrapper: { marginTop: 4, display: 'flex', alignItems: 'center' },
  checkIcon: { marginLeft: 0.5 },
  name: { fontSize: 18, fontWeight: 600, color: '#060F14' },
  description: { fontSize: 14, fontWeight: 500, color: '#214254', marginTop: 1 },
  buttonsWrapper: { display: 'flex', alignItems: 'center', marginTop: 2 },
  storeBtn: {
    backgroundColor: '#4288F0',
    textTransform: 'capitalize',
    borderRadius: '5px',
    ':hover': { backgroundColor: '#4288F0' },
  },
  moreBtn: { marginLeft: 1, color: '#4288F0' },
};

export function UserHeader(): ReactElement {
  return (
    <>
      <Box sx={styles.headerImageWrapper}>
        <Box sx={styles.headerImageContainer}>
          <Box component="img" sx={styles.headerImage} src={UserHeaderImage} alt="user-header" />
        </Box>
        <Box component="img" sx={styles.profileAvatar} src={UserProfileImage} alt="profile-image" />
      </Box>
      <Box sx={styles.contentWrapper}>
        <Box sx={styles.nameWrapper}>
          <Typography sx={styles.name}>Influncer Name</Typography>
          <CheckCircleIcon color="success" fontSize="small" sx={styles.checkIcon} />
        </Box>
        <Typography sx={styles.description}>Here you will select the desired product</Typography>
        <Box sx={styles.buttonsWrapper}>
          <Button sx={styles.storeBtn} variant="contained">
            Store Link
          </Button>
          <IconButton sx={styles.moreBtn}>
            <MoreHorizIcon />
          </IconButton>
        </Box>
      </Box>
    </>
  );
}
