import { Action } from 'common/interfaces/root';
import { GET_ME, GET_ME_ERROR, GET_ME_SUCCESS, PUT_LANGUAGE } from './actions-types';

export const putLanguage = (lang: string): Action => ({
  type: PUT_LANGUAGE,
  payload: lang,
});

export const getMe = () => ({
  type: GET_ME,
});

export const getMeSuccess = () => ({
  type: GET_ME_SUCCESS,
});

export const getMeError = (error: Error) => ({
  type: GET_ME_ERROR,
  payload: error,
});
