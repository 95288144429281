import { Box } from '@mui/material';
import { theme } from 'common/constants/theme';
import { Styles } from 'common/types';
import { ReactElement, ReactNode } from 'react';

const getStyles = (): Styles => ({
  container: { display: 'flex', height: '100%', backgroundColor: theme.palette.background.default },
  contentContainer: {
    minHeight: 'calc(100vh)',
    padding: '15px 16px 75px 16px',
    boxSizing: 'border-box',
    maxWidth: 1140,
    margin: '0 auto',
    width: '100%',
  },
});

interface Props {
  children?: ReactNode;
}

export function Layout({ children }: Props): ReactElement {
  const styles = getStyles();

  return (
    <Box sx={styles.container}>
      <Box sx={styles.contentContainer}>{children}</Box>
      {/* <Menu /> */}
    </Box>
  );
}
