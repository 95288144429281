import { combineReducers } from '@reduxjs/toolkit';
import rootReducer from './root';

export const getReducers = () =>
  combineReducers({
    root: rootReducer,
  });

const reducers = getReducers();

export type State = ReturnType<typeof reducers>;
