import { ReactElement } from 'react';
import { ContentsList, UserHeader } from './components';

export function User(): ReactElement {
  return (
    <>
      <UserHeader />
      <ContentsList />
    </>
  );
}
