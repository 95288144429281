import { Box } from '@mui/material';
import { ReactElement } from 'react';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Styles } from 'common/types';
import { useLocation, useNavigate } from 'react-router-dom';
import { theme } from 'common/constants/theme';

interface StylesProps {
  preview: string;
}

const getStyles = ({ preview }: StylesProps): Styles => ({
  container: {
    width: '100%',
    aspectRatio: '1 / 2',
    backgroundImage: `url(${preview})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    position: 'relative',
  },
  playIcon: { position: 'absolute', right: 16, top: 16, color: theme.palette.background.paper },
});

interface Props {
  preview: string;
  id: number | string;
}

export function ContentEntry({ preview, id }: Props): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();

  const styles = getStyles({ preview });

  const handleNavigate = () => {
    navigate(`${location.pathname}/c/${id}`);
  };

  return (
    <Box sx={styles.container} onClick={handleNavigate}>
      <PlayCircleOutlineIcon sx={styles.playIcon} />
    </Box>
  );
}
