import { Box, IconButton } from '@mui/material';
import { Styles } from 'common/types';
import { MouseEvent, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { theme } from 'common/constants/theme';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CommentIcon from '@mui/icons-material/Comment';
import { useSwiperSlide } from 'swiper/react';
import ShareIcon from '@mui/icons-material/Share';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Player from 'xgplayer';
import { breakpointsNumber } from 'common/constants/breakpoints';
import { useWindowSize } from 'common/hooks';
import { nanoid } from 'nanoid';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { useSwipeable } from 'react-swipeable';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { ProductCard } from '../product-card';
import { CommentsSection } from '../comments-section';
import { SlideContentType, SliderProduct } from '../types';
import '../_styles.css';

const getStyles = (): Styles => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
  },
  content: {
    height: '100%',
    width: '100%',
    position: 'relative',
    color: theme.palette.background.paper,
    backgroundColor: '#000',
  },
  playerWrapper: { width: '100%', height: '100%' },
  backIcon: { position: 'absolute', left: 16, top: 20, color: theme.palette.background.paper, zIndex: 2 },
  actionsIcon: { color: theme.palette.background.paper },
  actions: { position: 'absolute', right: 16, bottom: 16, display: 'flex', flexDirection: 'column', gap: 1, zIndex: 2 },
  overlay: { position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' },
  unmuteButton: { position: 'absolute', top: 16, right: 16, color: theme.palette.background.paper },
});

interface Props {
  url: string;
  type: SlideContentType;
  id: string | number;
  product: SliderProduct;
  backPath?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function VideoOverlay({ backPath, url, type, id, product }: Props): ReactElement {
  const navigate = useNavigate();
  const { content_id } = useParams<{ content_id: string }>();
  const swipeHandlers = useSwipeable({
    onSwipedRight: () => navigate(backPath),
  });

  const { width } = useWindowSize();
  const isTabletPlus = width >= breakpointsNumber.tablet;

  const { isActive } = useSwiperSlide();

  const [isProductShowed, setIsProductShowed] = useState<boolean>(false);
  const [isCommentsShowed, setIsCommentsShowed] = useState<boolean>(false);
  const [isMuted, setIsMuted] = useState<boolean>(!isTabletPlus);

  const player = useRef<Player>();
  const playerId = useRef(nanoid());
  const productTimeout = useRef<number>();
  const playTimeout = useRef<number>();
  const contentRef = useRef<HTMLDivElement>();
  const muteButtonRef = useRef<HTMLButtonElement>();

  const styles = getStyles();

  const handleProductOpen = useCallback(() => {
    setIsProductShowed(true);
  }, []);

  const handleProductClose = useCallback(() => {
    setIsProductShowed(false);
  }, []);

  const handleProductClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    handleProductOpen();
  };

  const handleCommentsToggle = () => {
    setIsCommentsShowed(isCommentsShowed => !isCommentsShowed);
  };

  const handleCommentsClick = (e: MouseEvent<HTMLElement>) => {
    if (isProductShowed) return;
    e.stopPropagation();
    handleCommentsToggle();
  };

  const handleGoBack = () => {
    navigate(backPath);
  };

  const handlePlay = () => {
    if (isProductShowed) return;

    if (player.current.paused) {
      player.current.play();
      return;
    }

    player.current.pause();
  };

  const handleMuted = useCallback(() => {
    player.current.muted = !player.current.muted;
    setIsMuted(isMuted => !isMuted);
  }, []);

  useEffect(() => {
    if (player.current) player.current.destroy();

    player.current = new Player({
      id: playerId.current,
      width: '100%',
      height: '100%',
      playsinline: true,
      controls: false,
      loop: true,
      disableLongPress: true,
      videoInit: true,
      'x5-video-player-type': 'h5',
      controlsList: ['nofullscreen', 'nodownload', 'noremoteplayback'],
      url,
    });

    if (!isTabletPlus) player.current.muted = true;

    return () => player.current.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  useEffect(() => {
    window.clearInterval(playTimeout.current);

    if (!isActive) {
      player.current.pause();
      return;
    }

    playTimeout.current = window.setTimeout(() => {
      player.current.play();
    }, 500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  useEffect(() => {
    if (isActive) {
      navigate(window.location.pathname.replace(content_id, String(id)));
    }
  }, [content_id, id, isActive, navigate]);

  useEffect(() => {
    window.clearTimeout(productTimeout.current);
    if (isActive) {
      productTimeout.current = window.setTimeout(() => {
        handleProductOpen();
      }, 3000);
      return;
    }
    handleProductClose();
  }, [isActive, handleProductOpen, handleProductClose]);

  return (
    <Box {...swipeHandlers} sx={styles.container}>
      <Box sx={styles.content} ref={contentRef}>
        <div id={playerId.current} />
        <Box sx={styles.overlay} onClick={handlePlay} />
        {backPath && (
          <IconButton sx={styles.backIcon} onClick={handleGoBack}>
            <ArrowBackIosNewIcon />
          </IconButton>
        )}
        <IconButton ref={muteButtonRef} sx={styles.unmuteButton} onClick={handleMuted}>
          {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
        </IconButton>
        <Box sx={styles.actions}>
          <IconButton sx={styles.actionsIcon} size="large">
            <FavoriteIcon />
          </IconButton>
          <IconButton sx={styles.actionsIcon} onClick={handleCommentsClick} size="large">
            <CommentIcon />
          </IconButton>
          <IconButton sx={styles.actionsIcon} size="large">
            <ShareIcon />
          </IconButton>
          <IconButton sx={styles.actionsIcon} size="large" onClick={handleProductClick}>
            <ShoppingCartIcon />
          </IconButton>
        </Box>
      </Box>
      <ProductCard
        title={product.name}
        price={product.price}
        image={product.image}
        link={product.url}
        open={isProductShowed}
        onClose={handleProductClose}
      />
      <CommentsSection open={isCommentsShowed} onClose={handleCommentsToggle} />
    </Box>
  );
}
