import { Box, IconButton } from '@mui/material';
import { Styles } from 'common/types';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { breakpointsNumber } from 'common/constants/breakpoints';
import { useWindowSize } from 'common/hooks';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Swiper, SwiperSlide } from 'swiper/react';
import { VideoOverlay } from './video-overlay';
import { SwiperHooks } from './swiper-hooks';
import { SliderContent, SwiperRef } from './types';
import { changeTargetSlideWrap, wrapTargetSlide } from './utils';

import 'swiper/css';

interface StylesProps {
  vh: number;
}

const getStyles = ({ vh }: StylesProps): Styles => ({
  container: { width: '100vw', height: `calc(${vh}px * 100)`, backgroundColor: '#F4F9FB', position: 'relative' },
  fake: { height: '100%', width: '100%', backgroundColor: '#000' },
  back: { position: 'absolute', left: 16, top: '50%', transform: 'translaceY(-50%)', color: '#fff', zIndex: 2 },
  next: { position: 'absolute', right: 16, top: '50%', transform: 'translaceY(-50%)', color: '#fff', zIndex: 2 },
});

interface Props {
  backPath?: string;
  contents: Record<number, SliderContent>;
  contentAmountBehind: number;
  contentAmountFront: number;
  initialContentId: string | number;
}

export function ContentsSlider({
  backPath,
  contents,
  initialContentId,
  contentAmountBehind,
  contentAmountFront,
}: Props): ReactElement {
  const { height, width } = useWindowSize();

  const isTabletPlus = width >= breakpointsNumber.tablet;

  const styles = getStyles({ vh: height * 0.01 });

  const [currentElementIndex, setCurrentElementIndex] = useState<number>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Number(Object.entries(contents).find(([_, value]) => value.id === initialContentId)[0])
  );
  const [slides, setSlides] = useState<Array<Partial<SliderContent>>>(
    wrapTargetSlide(contents, currentElementIndex, contentAmountBehind, contentAmountFront)
  );

  const swiperRef = useRef<SwiperRef>();

  const replaceSlides = (targetSlideIndex: number) => {
    setSlides(changeTargetSlideWrap(slides, contents, targetSlideIndex));
  };

  const getInitialSlide = () => {
    return slides.findIndex(slide => slide.id === initialContentId);
  };

  const handleNextSlide = () => {
    swiperRef.current.slideNext();
  };

  const handlePreviousSlide = () => {
    swiperRef.current.slidePrev();
  };

  useEffect(() => {
    const onTouchMove = (e: Event) => {
      e.preventDefault();
    };

    window.addEventListener('touchmove', onTouchMove, { passive: false });

    return () => window.removeEventListener('touchmove', onTouchMove);
  }, []);

  return (
    <Box sx={styles.container}>
      {isTabletPlus && (
        <IconButton size="large" sx={styles.back} onClick={handlePreviousSlide}>
          <ArrowBackIosIcon />
        </IconButton>
      )}
      {isTabletPlus && (
        <IconButton size="large" sx={styles.next} onClick={handleNextSlide}>
          <ArrowForwardIosIcon />
        </IconButton>
      )}
      <Swiper
        slidesPerView={1}
        initialSlide={getInitialSlide()}
        direction={isTabletPlus ? 'horizontal' : 'vertical'}
        noSwipingClass="swiper-no-swiping"
        // eslint-disable-next-line no-return-assign
        onSwiper={swiper => (swiperRef.current = swiper)}
      >
        <SwiperHooks
          setCurrentElementIndex={setCurrentElementIndex}
          replaceSlides={replaceSlides}
          currentElementIndex={currentElementIndex}
        />
        {slides.map(el => (
          <SwiperSlide key={el.id}>
            {el.fake ? (
              <Box sx={styles.fake} />
            ) : (
              <VideoOverlay backPath={backPath} url={el?.url} id={el?.id} type={el?.type} product={el.product} />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
}
