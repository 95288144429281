export enum SlideContentType {
  VIDEO = 'video',
  IMAGE = 'image',
}

export interface SliderContent {
  id: string | number;
  url: string;
  type: SlideContentType;
  product: SliderProduct;
  fake?: boolean;
}

export interface SliderProduct {
  url: string;
  image: string;
  name: string;
  price: string;
}

export interface SwiperRef {
  slidePrev: () => void;
  slideNext: () => void;
}
