import image_1 from 'common/mock/images/image_1.png';
import image_2 from 'common/mock/images/image_2.png';
import image_3 from 'common/mock/images/image_3.png';
import image_4 from 'common/mock/images/image_4.png';

export const mock = [
  {
    id: 'RfWqWhpxyg',
    preview: image_1,
  },
  {
    id: '6BkmpiAKaF',
    preview: image_2,
  },
  {
    id: 'QmkXrLxIVR',
    preview: image_3,
  },
  {
    id: 'Iq1h73zoIS',
    preview: image_4,
  },
];
