import { Box } from '@mui/material';
import { breakpointsNumber } from 'common/constants/breakpoints';
import { theme } from 'common/constants/theme';
import { Styles } from 'common/types';
import { ReactElement } from 'react';
import { ContentEntry } from '../content-entry';
import { mock } from './mock';

const styles: Styles = {
  container: {
    display: 'grid',
    gap: 2.5,
    gridTemplateColumns: 'repeat(2, 1fr)',
    padding: theme.spacing(0, 2.5, 2.5),

    [theme.breakpoints.up(breakpointsNumber.tablet)]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },

    [theme.breakpoints.up(breakpointsNumber.laptopL)]: {
      gridTemplateColumns: 'repeat(5, 1fr)',
    },

    [theme.breakpoints.up(breakpointsNumber.desktop)]: {
      gridTemplateColumns: 'repeat(6, 1fr)',
    },
  },
};

export function ContentsList(): ReactElement {
  return (
    <Box sx={styles.container}>
      {mock.map(el => (
        <ContentEntry key={el.id} preview={el.preview} id={el.id} />
      ))}
    </Box>
  );
}
