import { SlideContentType, SliderContent } from 'common/ui/videos-slider/types';
import video_1 from 'common/mock/videos/video_1.mp4';
import video_2 from 'common/mock/videos/video_2.mp4';
import video_3 from 'common/mock/videos/video_3.mp4';
import video_4 from 'common/mock/videos/video_4.mp4';
import image_1 from 'common/mock/images/product_image_1.jpg';
import image_2 from 'common/mock/images/product_image_2.jpg';
import image_3 from 'common/mock/images/product_image_3.jpg';
import image_4 from 'common/mock/images/product_image_4.jpg';

export const mock: Record<number, SliderContent> = {
  0: {
    id: 'RfWqWhpxyg',
    url: video_1,
    type: SlideContentType.VIDEO,
    product: {
      url: 'https://www.amazon.com/SIRUITON-Wireless-Handheld-Portable-Cordless/dp/B0962DWQ2T/re%5B%E2%80%A6%5Dqid=1656931494&sprefix=ledge+vacuum+cleaner,aps,95&sr=8-3',
      image: image_1,
      price: '169.99$',
      name: 'SIRUITON Handheld Vacuum 9000Pa',
    },
  },
  1: {
    id: '6BkmpiAKaF',
    url: video_2,
    type: SlideContentType.VIDEO,
    product: {
      url: 'https://www.amazon.com/Romsto-Handheld-Portable-Freshness-Batteries/dp/B09TT2GLJ9/re%5B%E2%80%A6%5Dag+sealer&qid=1656931530&sprefix=bag+sealer,aps,98&sr=8-3',
      image: image_2,
      price: '15.98$',
      name: 'Mini Bag Sealer',
    },
  },
  2: {
    id: 'QmkXrLxIVR',
    url: video_3,
    type: SlideContentType.VIDEO,
    product: {
      url: 'https://www.amazon.com/Stretcher-Multi-Level-Adjustable-Design-Spine-Decompression/dp/B09XBLKP17/ref=sr_1_2_sspa?crid=286L1M6P14BZU&keywords=back+cracker&qid=1656931566&sprefix=back+cracker%2Caps%2C100&sr=8-2-spons&psc=1&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUFZR09TMkg5RTMxUSZlbmNyeXB0ZWRJZD1BMDM3Nzg0ODE5SlNLUE1ES0FZUDAmZW5jcnlwdGVkQWRJZD1BMDQyNzI2MDJWRE5BSTNWMEdCSTEmd2lkZ2V0TmFtZT1zcF9hdGYmYWN0aW9uPWNsaWNrUmVkaXJlY3QmZG9Ob3RMb2dDbGljaz10cnVl',
      image: image_3,
      price: '99.99$',
      name: 'Back Stretcher for Lower Back Pain',
    },
  },
  3: {
    id: 'Iq1h73zoIS',
    url: video_4,
    type: SlideContentType.VIDEO,
    product: {
      url: 'https://www.amazon.com/Nonstick-Pancake-cookware-Omelette-Aluminium/dp/B08GY5MTRQ/ref=sr_1_3?crid=2OXAYSFV3MDBD&keywords=egg+4+maker+pan&qid=1656931603&sprefix=egg+4+maker+pan%2Caps%2C82&sr=8-3',
      image: image_4,
      price: '101.49$',
      name: 'Egg Frying Pan',
    },
  },
};
