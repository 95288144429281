import { Box, Dialog, Typography } from '@mui/material';
import { Styles } from 'common/types';
import { ReactElement } from 'react';

const styles: Styles = {
  container: { padding: 2, height: 200 },
  comments: { fontSize: 14, textAlign: 'center' },
  dialog: {
    '& .MuiDialog-container': {
      alignItems: 'flex-end',
    },
  },
};

interface Props {
  open: boolean;
  onClose: () => void;
}

export function CommentsSection({ open, onClose }: Props): ReactElement {
  return (
    <Dialog open={open} onClose={onClose} sx={styles.dialog} fullWidth>
      <Box sx={styles.container}>
        <Typography sx={styles.comments}>Comments</Typography>
      </Box>
    </Dialog>
  );
}
