import { useSwiper } from 'swiper/react';
import { Dispatch, ReactElement, SetStateAction, useEffect } from 'react';

interface Props {
  replaceSlides: (targetSlideIndex: number) => void;
  setCurrentElementIndex: Dispatch<SetStateAction<number>>;
  currentElementIndex: number;
}

export function SwiperHooks({ setCurrentElementIndex, currentElementIndex, replaceSlides }: Props): ReactElement {
  const swiper = useSwiper();

  // const slidedManually = useRef<boolean>(false);

  useEffect(() => {
    swiper.off('slideChangeTransitionEnd');

    swiper.on('slideChangeTransitionEnd', swiper => {
      const isNextSlide = swiper.previousIndex < swiper.activeIndex;
      const targetElementIndex = isNextSlide ? currentElementIndex + 1 : currentElementIndex - 1;
      setCurrentElementIndex(targetElementIndex);
      replaceSlides(swiper.activeIndex);
    });
  }, [replaceSlides, setCurrentElementIndex, swiper, currentElementIndex]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
}
