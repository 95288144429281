import { getContentsLayoutRoutes, getLayoutRoutes } from 'common/constants/routes';
import { putLanguage } from 'common/store/actions';
import { selectRootLanguage } from 'common/store/selectors';
import { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { Layout } from 'root/layout';

export function App() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const language = useSelector(selectRootLanguage);

  const layoutRoutes = getLayoutRoutes().filter(route => route.isAvailable);
  const contentsLayoutRoutes = getContentsLayoutRoutes().filter(route => route.isAvailable);

  useEffect(() => {
    if (!language) return;
    i18n.changeLanguage(language);
  }, [language, i18n]);

  useEffect(() => {
    const lang = localStorage.getItem('lang');
    dispatch(putLanguage(lang || i18n.language));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n]);

  return (
    <Suspense>
      <Routes>
        <Route element={<Layout />}>
          {layoutRoutes.map(route => (
            <Route path={route.path} key={route.path} element={<route.component />} />
          ))}
        </Route>
        <Route>
          {contentsLayoutRoutes.map(route => (
            <Route path={route.path} key={route.path} element={<route.component />} />
          ))}
        </Route>
      </Routes>
    </Suspense>
  );
}
