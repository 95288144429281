import { Box, Button, Fade, IconButton, Typography } from '@mui/material';
import { Styles } from 'common/types';
import { ReactElement, useRef } from 'react';
import { theme } from 'common/constants/theme';
import { useSwipeable } from 'react-swipeable';
import { useOutsideClick, useWindowSize } from 'common/hooks';
import CloseIcon from '@mui/icons-material/Close';
import { breakpointsNumber } from 'common/constants/breakpoints';

interface StylesProps {
  image: string;
  isTabletPlus: boolean;
}

const getStyles = ({ image, isTabletPlus }: StylesProps): Styles => ({
  wrapper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '12px',
    position: 'fixed',
    bottom: '32px',
    width: 'calc(100% - 64px)',
    maxWidth: isTabletPlus ? '600px' : '100%',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 9999,
  },
  container: { display: 'flex', position: 'relative', padding: 2 },
  content: { display: 'flex', flexDirection: 'column', marginLeft: 2 },
  title: { fontSize: 12 },
  price: { fontSize: 11, color: '#4288F0', marginTop: 1 },
  dialog: {
    '& .MuiDialog-container': {
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },
  },
  btn: {
    maxWidth: 65,
    width: '100%',
    backgroundColor: '#4288F0',
    borderRadius: '5px',
    marginTop: 1.5,
    textTransform: 'capitalize',
    ':hover': { backgroundColor: '#4288F0' },
  },
  image: {
    backgroundImage: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    width: 50,
    height: 80,
  },
  exitBtn: { position: 'absolute', top: 4, right: 4 },
});

interface Props {
  title: string;
  price: string;
  link: string;
  image: string;
  open: boolean;
  onClose: () => void;
}

export function ProductCard({ title, price, open, onClose, link, image }: Props): ReactElement {
  const { width } = useWindowSize();
  const isTabletPlus = width >= breakpointsNumber.tablet;
  const styles = getStyles({ image, isTabletPlus });

  const swipeHandlers = useSwipeable({
    onSwipedDown: () => onClose(),
  });

  const containerRef = useRef<HTMLDivElement>();

  useOutsideClick(containerRef, () => {
    if (open) {
      setTimeout(() => {
        onClose();
      }, 200);
    }
  });

  const handleNavigateToShop = () => {
    window.open(link);
  };

  return (
    <Fade in={open}>
      <Box ref={containerRef} sx={styles.wrapper}>
        <Box {...swipeHandlers} sx={styles.container} className="swiper-no-swiping">
          <IconButton sx={styles.exitBtn} onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Box sx={styles.image} />
          <Box sx={styles.content}>
            <Typography sx={styles.title}>{title}</Typography>
            <Typography sx={styles.price}>{price}</Typography>
            <Button sx={styles.btn} variant="contained" onClick={handleNavigateToShop}>
              Shop
            </Button>
          </Box>
        </Box>
      </Box>
    </Fade>
  );
}
