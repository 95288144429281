import { Overview } from 'modules/overview';
import { User } from 'modules/user';
import { UserCarousel } from 'modules/user-carousel';
import { FunctionComponent } from 'react';

interface Route {
  path: string;
  component: FunctionComponent;
  isAvailable: boolean;
}

export const getLayoutRoutes = (): Array<Route> => [
  {
    path: '/',
    component: Overview,
    isAvailable: true,
  },
];

export const getContentsLayoutRoutes = (): Array<Route> => [
  {
    path: '/p/:user_id',
    component: User,
    isAvailable: true,
  },
  {
    path: '/p/:user_id/c/:content_id',
    component: UserCarousel,
    isAvailable: true,
  },
];
